import { Box, Button } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import React from 'react';

import computeBannerPresence from './computeBannerPresence';
import computeChatMentions from './computeChatMentions';
import computeLinkSharing from './computeLinkSharing';
import computeOverview from './computeOverview';
import computeOverviewByCreator from './computeOverviewByCreator';
import computeSocialPostsDeliverables from './computeSocialPostsDeliverables';
import computeStreams from './computeStreams';
import computeVoiceMentions from './computeVoiceMentions';

export const triggerDownload = (campaign) => () => {
  import('xlsx')
    .then((XLSX) => {
      createDocument(campaign, XLSX)();
    })
    .catch((error) => console.error('XLSX', error));
};

const createDocument = (campaign, XLSX) => () => {
  const wb = XLSX.utils.book_new();

  const addSheet = (wb, name, data) => {
    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data), name);
  };

  addSheet(wb, 'Overview', computeOverview(campaign));
  addSheet(wb, 'Streams', computeStreams(campaign));
  addSheet(wb, 'Streams by Creator', computeOverviewByCreator(campaign));
  addSheet(wb, 'Voice Mentions', computeVoiceMentions(campaign));
  addSheet(wb, 'Banner Presence', computeBannerPresence(campaign));
  addSheet(wb, 'Link Sharing', computeLinkSharing(campaign));
  addSheet(
    wb,
    'Social Posts Deliverable',
    computeSocialPostsDeliverables(campaign)
  );
  addSheet(wb, 'Chat Mentions', computeChatMentions(campaign));

  XLSX.writeFile(
    wb,
    `Aggero-Campaign-${campaign.name}-${new Date()
      .toLocaleString()
      .replace(',', '')}.xlsx`
  );
};

const DownloadCampaign = ({ campaign }) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={triggerDownload(campaign)}
    >
      <Box clone mr={1}>
        <GetAppIcon />
      </Box>
      Download
    </Button>
  );
};

export default DownloadCampaign;
