import { isoLanguagesMap } from '../../../common/iso-languages';
import { computeSummary as computeStreamOverview } from '../PerformanceTab/Streams/SummaryTable';
import { videoIsFromStreamingPlatform } from '../utils';

export const computeOverviewByCreator = (campaign) => {
  const { videos, campaign_influencers } = campaign;

  const rows = [];

  // Only use the videos from the streaming platforms
  const streamVideos = videos.filter((video) =>
    videoIsFromStreamingPlatform(video)
  );

  const creators = campaign_influencers.map((influencer) => {
    return {
      name: influencer.name || influencer.twitch_external_id,
      id: influencer.id,
    };
  });

  const statsByCreator = creators.map((creator) => {
    const creatorVideos = streamVideos.filter((video) =>
      video.campaign_influencer_ids.includes(creator.id)
    );

    const {
      streams,
      hoursStreamed,
      hoursWatched,
      comments,
      engagementRate,
      totalViews,
      avgCCV,
      peakCCV,
      totalEMV,
      followers,
    } = computeStreamOverview({ usedVideos: creatorVideos });

    const languageCode =
      creatorVideos.find((video) => video.language)?.language || '';
    const language = isoLanguagesMap[languageCode]?.name || '';

    return {
      creator,
      streams,
      hoursStreamed,
      hoursWatched,
      comments,
      engagementRate,
      totalViews,
      avgCCV,
      peakCCV,
      totalEMV,
      followers,
      language,
    };
  });

  // Sort by number of streams/videos
  statsByCreator.sort((a, b) => b.streams - a.streams);

  // Header
  rows.push([
    'Creator',
    'Streams/Videos',
    'Hrs. Watched',
    'Duration Hrs.',
    'Followers',
    'Comments',
    'Engagement',
    'Avg. CCV',
    'Peak CCV',
    'Views',
    'Value',
    'Language',
  ]);

  rows.push(
    ...statsByCreator.map((stat) => [
      stat.creator.name,
      stat.streams,
      stat.hoursWatched,
      stat.hoursStreamed,
      stat.followers,
      stat.comments,
      stat.engagementRate,
      stat.avgCCV,
      stat.peakCCV,
      stat.totalViews,
      stat.totalEMV,
      stat.language,
    ])
  );

  return rows;
};

export default computeOverviewByCreator;
